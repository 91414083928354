<template>
  <article class="vacancyCard" v-bind="initData">
    <div class="vacancyCard__view" v-if="vacancy.video">
      <img class="vacancyCard__btn" @click="play(vacancy.video)" src="../../assets/img/btn-play.svg" />
      <p class="vacancyCard__btn--text" @click="play(vacancy.video)">{{ vacancy.btn }}</p>
    </div>
    <div class="vacancyCard__container">
      <img class="vacancyCard-image" :src="urlCloudFront(vacancy.image)" :alt="vacancy.title" />
      <div v-if="vacancy.video" @click="play(vacancy.video)" class="gradiente"></div>
    </div>

    <div class="vacancyCard-data">
      <div class="vacancyInfo">
        <h3 class="vacancyInfo-title">{{ vacancy.title }}</h3>
        <p class="vacancyInfo-cities">{{ vacancy.city }}</p>
        <p class="vacancyInfo-experience">{{ vacancy.experience === 0 ? "" : vacancy.experience }} {{ message }}</p>
      </div>
      <div class="vacancyDescription">
        <strong>{{ locale.jobs.description }}</strong>
        <p class="vacancyDescription-text" v-html="vacancy.description"></p>
      </div>
      <div class="vacancyRequirements">
        <strong>{{ locale.jobs.requirements }}</strong>
        <ul>
          <li v-for="(requirement, idx) in vacancy.requirements" :key="idx">{{ requirement }}</li>
        </ul>
      </div>
      <router-link :to="{ path: `/jobs/${vacancy._id}`, hash: '#jobs' }" class="vacancyCard__button">
        <button class="vacancyCard-register primary-button">
          {{ getTitle(vacancy.title) }}
        </button>
      </router-link>
    </div>
  </article>
</template>

<script>
import { parseMessage } from "../../assets/js/utils/parseMessage";

export default {
  props: { vacancy: Object },
  data: function () {
    return {
      locale: { jobs: {} },
    };
  },
  computed: {
    initData: async function () {
      await this.getLang();
    },
    message: function () {
      const userLang = localStorage.getItem("user-language");
      return parseMessage(userLang, this.vacancy.experience, this.vacancy.title);
    },
  },
  methods: {
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    urlEncodeB64: function (url) {
      return btoa(url);
    },
    getLang: async function () {
      if (this.$store.getters["iflanguage"]) {
        const { contact } = await this.$store.getters["localeLang"];
        this.locale = contact;
      }
    },
    getTitle: function (title) {
      switch (title.toLowerCase()) {
        case "model":
          return this.locale.jobs.register;
        case "modelo":
          return this.locale.jobs.register;
        default:
          return this.locale.jobs.apply;
      }
    },
    play(url) {
      this.$router
        .push({
          path: "/jobs/watch",
          query: {
            v: this.urlEncodeB64(url),
            backto: "/jobs",
          },
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.vacancyCard {
  display: grid;
  position: relative;
  width: 288px;
  min-height: 512px;
  justify-items: center;
  background: $white;
  border-radius: 12px;
  font-size: 12px;
  box-shadow: $form_shadow;
  margin: 0 auto;
  .gradiente {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  }
  &__view {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 160px;
    cursor: pointer;
    z-index: 10;
    .vacancyCard__btn {
      width: 44px;
    }
    .vacancyCard__btn--text {
      font-size: 20px;
      font-family: $sec_font;
      margin-top: 15px;
      margin-left: 5px;
      color: #fff;
    }
  }
  &-image {
    width: 100%;
    height: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    object-position: center;
    object-fit: cover;
  }

  &-data {
    width: 100%;
    padding: 10px 12px;

    strong {
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  &__button {
    display: block;
    text-align: center;
  }

  &-register {
    cursor: pointer;
    font-family: $first_font;
    font-size: 16px;
    margin: 24px 0;
  }
  &__container {
    position: relative;
    width: 100%;
    height: 400px;
  }
}

.vacancyInfo {
  text-align: center;
  &-title {
    font-family: $sec_font;
    font-size: 19px;
    color: $primary_color;
  }
  &-experience {
    margin-top: 10px;
    border: 1px solid $chicago;
    border-radius: 12px;
    display: inline-block;
    padding: 5px 15px;
    color: $chicago;
    font-size: 12px;
  }
  &-cities {
    margin-top: 3px;
    font-size: 14px;
  }
}

.vacancyDescription {
  margin-top: 32px;
}

.vacancyRequirements {
  margin-top: 20px;
  li {
    margin-bottom: 3px;
    list-style: inside;
    text-transform: capitalize;
  }
}
@media (max-width: $tablet_width) {
  .vacancyCard {
    &-image {
      object-position: 0px 0px;
    }
  }
}
@media (min-width: $desktop_width) {
  .vacancyCard {
    width: 996px;
    grid-template-columns: 441px 555px;
    font-size: 16px;
    &__view {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 80%;
      left: 18%;
      .vacancyCard__btn--text {
        font-size: 20px;
        font-family: $sec_font;
        margin-top: 28px;
        color: #fff;
      }
    }
    &-image {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 12px;
      height: 100%;
      grid-row: 1 / 3;
    }

    &-register {
      height: 35px;
      margin: 24px 0;
    }

    &-data {
      padding: 10px 25px;
    }
    &__container {
      height: 100%;
    }
  }

  .vacancyInfo {
    &-title {
      font-size: 24px;
    }

    &-experience {
      font-size: 16px;
    }

    &-cities {
      font-size: 16px;
    }
  }
  .vacancyDescription {
    &-text {
      padding-right: $mpadding + 5;
    }
  }
}
@media only screen and (min-width: $desktop_width) and (max-width: $tv_xxl) {
  .vacancyCard {
    .gradiente {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      cursor: pointer;
      border-bottom-left-radius: 12px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    }
    &__view {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 50%;
      .vacancyCard__btn--text {
        font-size: 20px;
        font-family: $sec_font;
        margin-top: 15px;
        margin-left: 5px;
        color: #fff;
      }
    }
  }
}
</style>
