export function parseMessage(lang, number, title) {
  let message = "";
  if (lang === "es") {
    switch (number) {
      case 0:
        title.toLowerCase() === "modelo" ? (message = "Con Experiencia y Sin Experiencia") : (message = "Sin Experiencia");
        return message;

      case 1:
        message = "Año de Experiencia";
        return message;

      default:
        message = "Años de Experiencia";
        return message;
    }
  }

  if (lang === "en") {
    switch (number) {
      case 0:
        title.toLowerCase() === "model" ? (message = "With and without experience") : (message = "Without Experience");
        return message;

      case 1:
        message = "Year of Experience";
        return message;

      default:
        message = "Years of Experience";
        return message;
    }
  }
}
