<template>
  <div class="heroVacantsSection" v-bind="initData">
    <div class="heroVacantsSection-image"></div>
    <div class="heroVacantsSection-message">
      <h1>{{ locale.jobs.hero_title }}</h1>
      <p>{{ locale.jobs.hero_message }}</p>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data: () => {
      return {
        locale: { jobs: {} },
      };
    },
    metaInfo: function () {
      const title = this.locale ? "- " + this.locale.jobs.hero_title : "";
      return {
        title: `Models1A ${title}`,
      };
    },
    methods: {
      getLang: async function () {
        if (this.$store.getters["iflanguage"]) {
          const { contact } = await this.$store.getters["localeLang"];
          this.locale = contact;
        }
      },
    },
    computed: {
      initData: async function () {
        await this.getLang();
      },
    },
  };
</script>

<style lang="scss">
  .heroVacantsSection {
    height: 169px;
    position: relative;
    color: $white;
    &-image {
      z-index: -1;
      position: absolute;
      height: 100%;
      width: 60%;
      background-image: url("https://cdn.models1a.com/website/about/banner_jobs.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 20px;
    }

    &-message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      text-align: center;
      background-color: $primary_color;
      border-radius: 20px;
      h1 {
        font-weight: normal;
        font-family: $sec_font;
        margin-right: 18px;
        font-size: 30px;
      }
      p {
        margin-top: 10px;
        margin-right: 18px;
        font-size: 11px;
      }
    }

    &-message::before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      left: -15%;
      background-color: $primary_color;
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  @media (min-width: $pocket_width) {
    .heroVacantsSection {
      &-image {
        width: 57%;
      }
    }
  }

  @media (min-width: $tablet_width) {
    .heroVacantsSection {
      max-width: 968px;
      margin: auto;
      &-image {
        width: 47%;
      }
      &-message {
        h1 {
          font-size: 42px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: $desktop_width) {
    .heroVacantsSection {
      margin: auto;
      grid-template-columns: 968px;

      &-image {
        width: 46%;
        background-position-y: -10px;
      }
      &-message {
        h1 {
          font-size: 58px;
        }
        p {
          font-size: 21px;
        }
      }
    }
  }
</style>
